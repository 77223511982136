// extracted by mini-css-extract-plugin
export var articleWrapper = "l_py";
export var articleText = "l_pz h_gm h_fk";
export var header = "l_pB h_cV h_cr";
export var headerImageWrapper = "l_pC h_c8 h_cW h_c4 h_c3 h_c0 h_c5 h_db h_dJ";
export var headerGradient = "l_pD h_c8 h_cW h_c4 h_c3 h_c0 h_c5";
export var headerGradientOverlay = "l_pF h_c8 h_cW h_c4 h_c3 h_c0 h_c5";
export var headerContentWrapper = "l_pG h_dr h_dG h_dv h_dz h_dc h_cV";
export var headerContentWrapperAlt = "l_pH h_dr h_dG h_dv h_dz h_dc h_cV";
export var contentWrapper = "l_s h_cr";
export var dateTag = "l_pJ h_d6 h_fj h_gh h_cr";
export var icon1 = "l_pK h_fG";
export var icon2 = "l_pL h_fG h_fz";
export var tagBtn = "l_pM h_dT h_fv h_gg h_gs";
export var headerText = "l_pN h_cr h_d6 h_fk h_dN";
export var center = "l_pP h_gn";
export var videoIframeStyle = "l_pQ h_gS h_cr h_cC h_dq h_dT h_cM";
export var articleImageWrapper = "l_pR h_fk h_cV";
export var articleImageWrapperIcon = "l_pS h_fk h_cV";
export var articleRow = "l_pT h_dv";
export var quoteWrapper = "l_jj h_dt h_dG h_fk";
export var quoteBar = "l_pV h_cC";
export var quoteText = "l_pW";
export var authorBox = "l_pX h_cr";
export var authorRow = "l_pY h_dv h_dN h_fq";
export var separator = "l_pZ h_cr";
export var line = "l_g5 h_cr h_g5 h_fQ h_fq";
export var authorImage = "l_mG h_d0 h_cV h_cr h_fl";
export var authorText = "l_p0 h_d8 h_fl";
export var masonryImageWrapper = "l_p1";
export var bottomSeparator = "l_p2 h_cr h_fq";
export var linksWrapper = "l_p3 h_d8 h_gm";
export var comments = "l_p4 h_dv h_fq";
export var sharing = "l_p5 h_dr h_dv h_dz";
export var shareText = "l_p6 h_cr h_gn";
export var icon = "l_p7";
export var customRow = "l_p8 h_dv h_cV";
export var text = "l_p9";
export var SubtitleSmall = "l_qb";
export var SubtitleNormal = "l_qc";
export var SubtitleLarge = "l_qd";
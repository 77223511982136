// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "n_qK h_dv";
export var storyRowWrapper = "n_j0 h_j0 h_dB";
export var storyLeftWrapper = "n_qL h_dr h_dG";
export var storyWrapperFull = "n_qM h_fv";
export var storyWrapperFullLeft = "n_pj h_fv h_dr h_dG h_dv";
export var contentWrapper = "n_s h_j1";
export var storyLeftWrapperCenter = "n_qN h_j2 h_dr h_dv h_dG h_dz";
export var storyRightWrapperCenter = "n_qP h_j6";
export var storyHeader = "n_qQ h_j5 h_cr h_fk";
export var storyHeaderCenter = "n_j4 h_j4 h_cr h_fk h_gn h_dq";
export var storyParagraphCenter = "n_j3 h_j3 h_dq h_gn";
export var storyBtnWrapper = "n_qR h_r h_gP h_cr h_dr h_dx";
export var storyBtnWrapperCenter = "n_j8 h_j8 h_gP h_cr h_dr h_dv";
export var imageWrapper = "n_qS h_g2 h_cV";
export var imageWrapperFull = "n_qT h_cr h_cC h_H h_cV";
export var SubtitleSmall = "n_qb q_qb q_qW q_q6";
export var SubtitleNormal = "n_qc q_qc q_qW q_q7";
export var SubtitleLarge = "n_qd q_qd q_qW q_q8";
export var textLeft = "n_gm";
export var textCenter = "n_gn";
export var textRight = "n_gp";
// extracted by mini-css-extract-plugin
export var warningBackgroundlight = "d_j";
export var warningBackgrounddark = "d_k";
export var warningBackgroundcustom = "d_l";
export var bottom = "d_m";
export var ribbon = "d_n";
export var modal = "d_p";
export var bottomRight = "d_q";
export var btnWrapper = "d_r h_dv h_dG h_dz";
export var contentWrapper = "d_s";
export var container = "d_t";
export var titleWrapper = "d_v h_dv h_dG h_gn";
export var elementWrapper = "d_w h_dv h_dG h_gn";
export var cookieButtons = "d_x h_dr h_dv h_dG h_dz";
export var btn = "d_y h_dr h_dv h_dG";
export var link = "d_z";
export var decline = "d_B d_y h_dr h_dv h_dG";
export var row = "d_C";
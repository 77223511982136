// extracted by mini-css-extract-plugin
export var alignDiscLeft = "m_qf h_g9 h_dx h_gm";
export var alignLeft = "m_qg h_g9 h_dx h_gm";
export var alignDiscCenter = "m_qh h_hb h_dv h_gn";
export var alignCenter = "m_dG h_hb h_dv h_gn";
export var alignDiscRight = "m_qj h_hc h_dy h_gp";
export var alignRight = "m_qk h_hc h_dy h_gp";
export var footerContainer = "m_ql h_gK h_dN";
export var footerContainerFull = "m_qm h_gH h_dN";
export var footerHeader = "m_lB h_lB";
export var footerTextWrapper = "m_qn h_cr";
export var footerDisclaimerWrapper = "m_lH h_lH h_fb";
export var badgeWrapper = "m_qp h_cr h_dr h_dG h_dw h_dz";
export var verticalTop = "m_qq h_dr h_dz h_dF h_dC";
export var wide = "m_qr h_fq";
export var right = "m_qs h_dB";
export var line = "m_g5 h_g6 h_fm";
export var badgeDisclaimer = "m_qt h_dt h_dG h_dz";
export var badgeContainer = "m_qv h_dr h_dy h_dG";
export var badge = "m_qw";
export var padding = "m_qx h_f0";
export var end = "m_qy h_dy";
export var linkWrapper = "m_qz h_gs";
export var link = "m_z h_gs";
export var colWrapper = "m_qB h_fp";
export var consent = "m_f h_f h_dt h_dG";
export var disclaimer = "m_qC h_dr h_dz";
export var media = "m_qD h_dp h_gq";
export var itemRight = "m_qF";
export var itemLeft = "m_qG";
export var itemCenter = "m_qH";
export var exceptionWeight = "m_qJ q_rm";